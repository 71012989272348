<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动区域"
                    prop="list_province">
        <Address v-model="areaAddress"
                 @change="areaChange" />
      </el-form-item>
      <el-form-item label="是否启用风控验证"
                    required>
        <el-radio-group v-model="form.is_risk_control">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <Editor v-model="form.rule" />
      </el-form-item>
      <el-form-item label="起止时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="每天可参与总次数"
                    class="width300"
                    prop="day_num">
        <el-input type="text"
                  placeholder="请输入每天可参与总次数"
                  v-model.number="form.day_num"></el-input>
      </el-form-item>
      <el-form-item label="每个用户可参与次数"
                    class="width300"
                    prop="user_num">
        <el-input type="text"
                  placeholder="请输入每个用户可参与次数"
                  v-model.number="form.user_num"></el-input>
      </el-form-item>
      <el-form-item label="每个用户每天可参与次数"
                    class="width300"
                    prop="user_day_num">
        <el-input type="text"
                  placeholder="请输入每个用户每天可参与次数"
                  v-model.number="form.user_day_num"></el-input>
      </el-form-item>
      <el-form-item label="活动总参与数"
                    class="width300"
                    prop="total_num">
        <el-input type="text"
                  placeholder="请输入活动总参与数"
                  v-model.number="form.total_num"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
import { setActivity, getActivity } from '@/api/market'
import Editor from '@/components/Editor'
import Address from '@/components/Address'
export default {
  name: 'SetLucky',
  data () {
    return {
      areaAddress: {},
      dateTime: [],
      form: {
        type: 'QUESTIONNAIRE_ANSWER',
        title: '',
        rule: '',
        started_at: '',
        ended_at: '',
        province: '',
        city: '',
        district: '',
        net: '',
        user_day_num: 1,
        user_num: 1,
        total_num: 1,
        day_num: 1,
        is_risk_control: true
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        province: [
          { required: true, message: '至少选择省份', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Address,
    Editor
  },
  filters: {
  },
  computed: {

  },
  methods: {
    dateChange (e) {
      if (e) {
        this.form.started_at = e[0]
        this.form.ended_at = e[1]
      } else {
        this.form.started_at = ''
        this.form.ended_at = ''
      }
    },
    areaChange (address) {
      const { form } = this
      form.province = address.province
      form.city = address.city
      form.district = address.district
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(
            JSON.stringify(this.form)
          )
          const res = await setActivity(formData)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('questionnaire')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity

        activity.activity_id = id
        this.areaAddress = {
          province: activity.province,
          city: activity.city,
          district: activity.district
        }
        this.dateTime = [activity.started_at, activity.ended_at]
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
